import React,{ FC, Fragment } from 'react';
import SleepSoundCard from '../SleepSoundCard';

export interface ISleepSessionSlider {
  background_image: string;
  content_link: string;
  title: string;
  description: string;
  id?:number;
  testId?:string;
}
interface ISleepSessionSliderProps {
  data?: ISleepSessionSlider[];
}

const SleepSessionSlider: FC<ISleepSessionSliderProps> = (props) => {
  const { data } = props;
  return (
    <Fragment>
      <p className="text-3xl font-bold text-white">Sleep</p>
      <p className="text-1xl mt-4 text-lightYellowC1_2">Relaxing Sleep Sessions</p>

      <div className="customOverFlowClass" data-testid="sleep-session-slider">
        {data &&
          data.map((item: ISleepSessionSlider, index: number) => {
            const { background_image, content_link, title, description } = item;
            return <SleepSoundCard id={index} key={index} background_image={background_image} content_link={content_link} title={title} description={description} testId="sleep-session-slider-card"/>;
          })}
      </div>
    </Fragment>
  );
};

export default SleepSessionSlider;
