import React,{ FC } from 'react';

import { PencilIcon } from '@heroicons/react/24/solid';
import googleFitLogo from '../../assets/logos/googleFit.png';

interface ISleepEventCard {
  title: string;
  description: string;
  moreInfo?: string;
  isEditable?: boolean;
}

const SleepEventCard: FC<ISleepEventCard> = (props) => {
  const { title, description, moreInfo, isEditable } = props;
  return (
    <div className="bg-darkGray2 p-4 rounded-lg shadow mb-2 flex justify-between" data-testid="sleep-event-card">
      <div className="flex flex-col justify-between">
        <div className="text-lg font-bold" data-testid="sleep-event-card-title">
          {title}
        </div>
        <div className="text-sm">
          <span className="text-sm" data-testid="sleep-event-card-description">
            {description}
          </span>
          <span className="underline ml-1">{moreInfo}</span>
        </div>
        {!isEditable && (
          <div className="mt-2">
            <button className="bg-[#504D55] p-2 rounded-[5px] flex items-center">
              <img src={googleFitLogo} alt="GoogleFit" className="w-7" />
              <p className="ml-2 text-sm">Activate Google Fit</p>
            </button>
          </div>
        )}
      </div>
      {isEditable && <PencilIcon className="h-6 w-6 text-yellowC1" data-testid="edit-icon" />}
    </div>
  );
};

export default SleepEventCard;
