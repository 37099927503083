export enum TOAST_TYPE {
  ERROR = "error",
  SUCCESS = "success",
}

export enum TOAST_POSITION {
  TOP_RIGHT = "top-0 right-0",
  TOP_CENTER = "top-0 left-0 right-0",
  TOP_LEFT = "top-0 left-0",
  BOTTOM_CENTER = "bottom-0 left-0 right-0",
  BOTTOM_LEFT = "bottom-0 left-0",
  BOTTOM_RIGHT = "bottom-0 right-0",
}

export enum CHART_TYPE {
  PIE = "pie",
  AREA = "area",
  LINE = "line",
  BAR = "bar",
}
