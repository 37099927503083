import 'App.css';
import { AppPageWrap } from 'backend/auth_wrapper';
import Footer from 'components/AppHeaderFooter/Footer';
import Header from 'components/AppHeaderFooter/Header';
import LeftMenu from 'components/AppHeaderFooter/LeftMenu';
import useIsMobileScreen from 'components/Utils/CustomHooks/useIsMobileScreen';
import CompanyAccount from 'pages/CompanyAccountPage';
import Dashboard from 'pages/DashboardPage';
import DesignSystemPage from 'pages/DesignSystemPage';
import ExampleLoadingPage from 'pages/ExampleLoadingPage';
import LessonDetail from 'pages/LessonDetail';
import Resources from 'pages/ResourcesPage';
import SleepTodayPage from 'pages/SleepTodayPage';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useIsMobileScreen();
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  return (
    <Router>
      <AppPageWrap>
        <div className="bg-darkGray custom-height flex ">
          <LeftMenu collapsed={collapsed} toggleCollapse={toggleCollapse} />
          <Header collapsed={collapsed} />

          {/* <div className={`flex-grow flex flex-col ${collapsed ? '' : 'lg:ml-[256px]'} `}> */}
          {/* <div className="flex-grow"> */}
          <main className={`flex-grow pt-16 px-4 ${collapsed ? 'ml-16 header-width-collapsed' : 'md:ml-64 sm:ml-16  header-width-full'}  `}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/designsys" element={<DesignSystemPage />} />
              <Route path="/company-account" element={<CompanyAccount />} />
              <Route path="/loading-test" element={<ExampleLoadingPage />} />
              <Route path="/sleep-today" element={<SleepTodayPage />} />
              <Route path="/lesson-detail/:id" element={<LessonDetail />} />
            </Routes>
          </main>
        </div>
        {/* </div> */}
        {/* </div> */}
        <Footer collapsed={collapsed} />
      </AppPageWrap>
    </Router>
  );
};

// const MidnightGradient: React.FC = () => {
//   return (
//     <span className="pl-1 gradient-text text-lg">Midnight</span>
//   );
// };

export default App;
