import useUser from 'backend/hooks/useUser';
import ResourceCard from 'components/Resources/ResourceCard';

const Resources = () => {
  const {
    data: {
      body: { group },
    },
  } = useUser();

  interface ResourceItem {
    content: string;
    title: string;
    bg_color: string;
  }
  const resources: ResourceItem[] = group.resources;

  return (
    <div className="" data-testid="resource-page">
      <h1 className="text-3xl font-medium py-6 pl-6">Resources</h1>
      {resources.map((item, index: number) => {
        return <ResourceCard item={item} key={index} />;
      })}
    </div>
  );
};

export default Resources;
