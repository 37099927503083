import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { FC } from "react";
import useUser from "backend/hooks/useUser";
import GrayBox from "../components/Utils/GrayBox";


const CompanyAccount: FC = () => {
  const { data } = useUser();

  const admin_emails: string[] = data.body.group.admin_emails;

  return (
    <div data-testid="company-account-page">
      <h1 className="text-3xl font-medium py-6 pl-6 mb-8">Company Account</h1>
      <GrayBox>
        <h3 className="text-xl mb-6 ">Admin Users</h3>
        <p className="opacity-80 font-light text-sm mb-6">
          To add or remove administrator accounts, please email techsupport@chorussleep.com
        </p>

        <ul className="list-none space-y-2">
          {admin_emails.map((item, index) => (
            <li className="text-white flex items-center" key={index}>
              <EnvelopeIcon className="h-5 w-5 text-themeBlue mr-2" />
              <span className="opacity-80 font-light">{item}</span>
            </li>
          ))}
        </ul>
      </GrayBox>
    </div>
  );
};

export default CompanyAccount;
