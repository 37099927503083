import React,{ FC } from 'react';
import { Link } from 'react-router-dom';
import { usePastLessons } from '../../../backend/hooks/useLessons';
import { Loading } from '../../Utils/Loading';
import LessonCard from './LessonCard';

const PastLesson: FC = () => {
  const { data, isLoading } = usePastLessons();

  const lessons = data?.body?.lessons;

  if (isLoading) {
    return (
      <div className="mb-3 mt-3 flex justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="mb-3 mt-3">
      {lessons &&
        lessons.map((item: any) => {
          const { title, day, duration, id } = item;
          return (
            <Link to={`/lesson-detail/${id}`}>
              <LessonCard title={title} day={day} key={id} duration={duration} />
            </Link>
          );
        })}
    </div>
  );
};

export default PastLesson;
