import { useQueryClient } from "react-query";
import { logout } from "../backend/auth_funcs";
import Footer from "./AppHeaderFooter/Footer";
import React,{ FC } from "react";

interface DisabledDashboardProps {
  email: String;
}

const DisabledDashboard: FC<DisabledDashboardProps> = ({email}) => {
  const queryClient = useQueryClient();

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-black to-[#767090] flex items-center justify-center">
      <div className="max-w-2xl text-center space-y-4 mx-2">
        <div className="max-w-md w-full">
          <div className="bg-darkGray2 p-6 rounded text-left">
            <p className="text-sm opacity-50 mt-4 mb-8">
              The Chorus Sleep Dashboard is currently disabled for your organization or you don't have access.
            </p>
            <p className="mb-2 text-xs text-center opacity-50">
              Please contact{" "}
              <a className="underline" href="mailto:support@chorussleep.com">
                support@chorussleep.com
              </a>{" "}
              for help
            </p>
            {email && (
              <p className="mb-2 text-xs text-center opacity-50">
              You are logged in as {email}
            </p>
            )}
            <button
              type="button"
              onClick={() => {
                logout(queryClient);
              }}
              className="mt-5 w-full bg-blueC1 text-white py-2 rounded mb-8"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
      <Footer abs={true} />
    </div>
  );
};

export default DisabledDashboard;
