
export const quotes = [
  'Consistency is the key to a well-rested mind and body.',
  'Let the sun guide your sleep schedule for a rejuvenated life.',
  'Exercise is not just a task, but a daily commitment to your well-being.',
  'A consistent sleep schedule is a gift you give yourself every day.',
  'Embrace the light to energize your day and settle into peaceful nights.',
  'Exercise today for a stronger and healthier tomorrow.',
  'Sleeping and waking up at the same time creates harmony within.',
  'Sunlight fuels your energy, and moonlight soothes your soul.',
  "Don't just dream of a healthier life; live it every day.",
  'Keep your sleep in check, and your dreams will follow suit.',
  'The right amount of light at the right time can make all the difference.',
  "Exercise isn't just about the body; it's a celebration of life.",
  'A consistent sleep schedule is the foundation of a well-rested existence.',
  'Bask in the sunlight and let it ignite your inner fire.',
  "Exercise is the key that unlocks your body's full potential.",
  'A well-rested mind paves the way for a productive day.',
  "Sunlight is nature's energy drink; savor it daily.",
  "Don't skip a beat; exercise to the rhythm of a healthier life.",
  'Sleep well and wake up refreshed to seize the day.',
  'Let the sun be your alarm clock and the moon your lullaby.',
];

export const sleepEvent = [
  {
    title: 'Google Fit Integration',
    description: 'Below you may integrate with Google Fit and fetch sleep data.',
    moreInfo: 'Tap here to learn more.',
    isEditable:false
  },
  {
    title: 'Morning Journal',
    description: 'How did you sleep? Check in now for the most accurate insights.',
    isEditable:true

  },
  {
    title: 'Evening and Nap Journal',
    description: 'Complete your evening journal toward the end of your day. Reporting on your day can help you understand patterns that impact sleep.',
    isEditable:true
  
},
];
