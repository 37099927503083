import React, { FC, Fragment } from 'react';
import coachImage from '../../assets/logos/woman_crying.png';
const SleepCoachCard: FC = () => {
  return (
    <Fragment>
      <p className="text-3xl font-bold text-white">Connect</p>
      <p className="text-1xl mt-4 text-lightYellowC1_2">My Sleep Coach</p>
      <div className="bg-darkPurpleC2 p-4 rounded-lg shadow mb-2 flex  mt-4 items-center flex-col md:flex-row">
        <img src={coachImage} alt="coach" className="w-32" />
        <p className="text-3xl text-center">Connect with your Sleep Coach</p>
      </div>
    </Fragment>
  );
};

export default SleepCoachCard;
