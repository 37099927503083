import { sleepEvent } from 'assets/staticData';
import { useToday } from 'backend/hooks/useUser';
import { PastLesson, SleepCardSlider, SleepCoachCard, SleepEventCard, SleepScoreChart } from 'components/SleepToday';
import SleepSessionSlider from 'components/SleepToday/SessionSlider/SleepSessionSlider';
import { LoadingPage } from 'components/Utils/Loading';

const SleepTodayPage = () => {
  const { isLoading, data: { body: { chart_data, sounds, essentials, sessions } } = { body: { chart_data: [], sounds: [], essentials: [], sessions: [] } } } = useToday();

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="mt-5" data-testid="sleep-today-page">
      <SleepScoreChart data={chart_data} />
      <p className="text-1xl mt-4 text-white mt-2 font-bold underline mb-3">See Sleep History</p>

      {sleepEvent.map((item, index) => (
        <SleepEventCard title={item.title} description={item.description} key={index} isEditable={item.isEditable} moreInfo={item.moreInfo} />
      ))}

      <SleepSessionSlider data={sessions} />

      <SleepCardSlider data={sounds} subTitle="Sleep Sounds" cardType="sound" testId="sleep-sound-slider" />

      <SleepCardSlider data={essentials} testId="dive-deeper-slider" title="Dive Deeper & Learn" subTitle="Sleep Essentials" cardType="sleep" width={250} height={250} />

      <PastLesson />

      <SleepCoachCard />
    </div>
  );
};

export default SleepTodayPage;
