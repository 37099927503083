// Footer.js
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import useUser from '../../backend/hooks/useUser';
import React,{FC} from 'react';

interface IFooter{
  abs?: boolean,
  collapsed?: boolean
}



const Footer: FC<IFooter> = ({ abs = false, collapsed }) => {
  const { data } = useUser();

  let footerClassNames = `py-2 text-white text-opacity-50 text-sm flex flex-col items-center justify-center px-3 ${!data && 'w-full ml-0 sm:ml-0'} ${data && collapsed ? 'ml-16 header-width-collapsed' : data && !collapsed ? 'ml-16 header-width-collapsed' : 'sm:header-width-full'}`;
  if (abs) {
    footerClassNames += ' absolute bottom-0 left-1/2 transform -translate-x-1/2';
  } else {
    footerClassNames += '';
  }



  return (
    <footer className={footerClassNames}>
      <div className="mb-1">© 2023 Chorus Wellness, Inc.</div>
      <div className="mb-1">
        <a href="https://www.chorussleep.com" target="_blank" rel="noopener noreferrer">
          <span className="text-white border-b border-white border-opacity-25 text-opacity-50">chorussleep.com</span>

          <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4 ml-2" />
        </a>
      </div>
      <div className="flex justify-center sm:justify-start sm:space-x-3 md:space-x-4 space-x-2 flex-wrap md:flex-row ">
        <a href="https://www.chorussleep.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        <a href="https://www.chorussleep.com/privacy-policy//" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <a href="https://www.chorussleep.com/disclaimer/" target="_blank" rel="noopener noreferrer">
          Disclaimer
        </a>
      </div>
    </footer>
  );
};

export default Footer;
