import React,{ FC, ReactNode } from 'react';

interface ICustomButtonProps {
  disabled?: boolean;
  onClick: () => void;
  children: ReactNode;
}

const CustomButton: FC<ICustomButtonProps> = (props) => {
  const { disabled = false, onClick, children } = props;
  return (
    <button
      disabled={disabled}
      className={`bg-${disabled ? 'darkGray2' : 'themeBlue'} text-white font-light px-4 py-2 rounded mt-4 ${!disabled && 'hover:bg-blue-600 transition-colors duration-200'}  mb-3`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CustomButton;
