type RawDataItem = { label: string; value: number };

type RawData = RawDataItem[] | { [key: string]: RawDataItem[] };

type ProcessedData = {
  name: string;
  data: number[];
};

type XAxis = {
  type: string;
  categories: string[];
};

export const  processData = (rawData: RawData): { data: ProcessedData[]; xaxis: XAxis[] } =>{
  let data: ProcessedData[] = [];
  let xaxis: XAxis[] = [];

  const handleArray = (array: RawDataItem[], key: string): void => {
    let labels: string[] = [];
    let values: number[] = [];

    array.forEach(({ label, value }) => {
      labels.push(label);
      values.push(value);
    });

    data.push({
      name: formatString(key),
      data: values,
    });

    xaxis.push({
      type: 'category',
      categories: labels,
    });
  };

  if (Array.isArray(rawData)) {
    handleArray(rawData, 'data');
  } else {
    for (let key in rawData) {
      handleArray(rawData[key], key);
    }
  }

  return { data, xaxis };
}


const formatString = (inputString: string): string =>{
  return inputString.replace(/_/g, ' ')
                    .replace(/\b\w/g, char => char.toUpperCase());
}
