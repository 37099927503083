import { getAT, sendRQApiJson } from "./service";

export const apiGetLesson = async (id: string | undefined): Promise<any> => {
  return await sendRQApiJson("GET", `/content/lessons/${id}`, getAT(), null);
};

export const apiGetLessons = async (): Promise<any> => {
  return await sendRQApiJson("GET", `/content/lessons/meta`, getAT(), null);
};

export const apiGetPastLessons = async (): Promise<any> => {
  return await sendRQApiJson("GET", `/content/lessons/past`, getAT(), null);
};
