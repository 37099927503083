import useUser from 'backend/hooks/useUser';
import DashboardComponents from 'components/Dashboard/DashboardComponents';
import KeyInsights from 'components/Dashboard/KeyInsights';
import React, { ComponentType, Fragment } from 'react';

const Dashboard: React.FC = () => {
  return (
    <div id="dashboardPage">
      <h1 className="text-3xl font-medium py-6 ">Dashboard</h1>
      <Boxes components={DashboardComponents()} />
    </div>
  );
};

interface BoxProps {
  Component: ComponentType<any>;
  props?: any;
  space: string;
}

interface BoxesProps {
  components: BoxProps[];
}

const Boxes: React.FC<BoxesProps> = ({ components }) => {
  const {
    data: {
      body: {
        group: { dash_charts_enabled, insights },
      },
    },
  } = useUser();

  const filteredComponents = dash_charts_enabled ? components.slice(0, 20) : components.slice(0, 2);


  return (
    <Fragment>
      <div className="mb-5">
        <h1 className="mb-4 text-xl">Key Insights</h1>
        {insights.map((item: any, index: React.Key | null | undefined) => {
          return <KeyInsights data={item} key={index} />;
        })}
      </div>
      <div className={`grid lg:grid-cols-2  sm:grid-cols-1 sm:grid-rows-${filteredComponents.length - 1} gap-5`}>
        {filteredComponents.map((box, index) => (
          <div className={box.space} key={index}>
            <div className={`bg-darkGray2 min-h-[400px] min-w-[200px] h-full w-full rounded-md flex flex-col justify-evenly items-center pb-3`}>
              <box.Component {...box.props} />
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Dashboard;
