import { CheckBadgeIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import React from "react";

type RedAlertIconProps = {
  iconType?: "error" | "success";
};

const AlertIcon: React.FC<RedAlertIconProps> = ({ iconType = "success" }) => {
  let iconObject = {
    error: <ExclamationTriangleIcon className="w-full h-full text-red-500" data-testid="error-icon" />,
    success: <CheckBadgeIcon className="w-full h-full text-green" data-testid="success-icon" />,
  };

  return <div className="w-10 h-10">{iconObject[iconType]}</div>;
};

export default AlertIcon;
