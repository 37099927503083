import React,{ FC } from 'react';

interface ResourceCardItem {
  content: string;
  title: string;
  bg_color: string;
}

const ResourceCard: FC<{ item: ResourceCardItem }> = ({ item }) => {
  const { bg_color, title, content } = item;

  return (
    <div className="border border-opacity-5 border-white rounded min-w-[100px] min-h-[100px] p-4 mb-6 break-all" style={{ background: bg_color }}>
      <h3 className="text-2xl mb-6 ">{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: content }} className="opacity-80 font-light text-sm mb-6 custom-underline"></div>
    </div>
  );
};

export default ResourceCard;
