import useStats from 'backend/hooks/useStats';
import useUser from 'backend/hooks/useUser';
import { CHART_TYPE } from '../Utils/constants/enum';
import CustomChart from './Chart/CustomChart';
import GroupDetail from './GroupDetail';


const DashboardComponents = () => {
  const {
    data: {
      body: { group: groupData },
    },
  } = useUser();

  const { data: statsData } = useStats();

  const moodData = statsData?.body?.mood;
  const dayTime = statsData?.body?.daytime_functioning;
  const fallAsleep = statsData?.body?.time_to_fall_asleep;
  const sleepDuration = statsData?.body?.sleep_duration;

  const { num_active, num_engaged } = groupData;

  const adoption = (num_engaged / num_active) * 100;

  let engagmentData = [adoption, 100 - adoption];




  return [
    { Component: GroupDetail, space: 'row-start-1 col-start-1' },
    {
      Component: CustomChart,
      props: {
        labels: ['Engaged', 'Incomplete'],
        colors: ['#519dc0', '#91c2d7'],
        title: 'Platform Engagement',
        chartData: adoption === 0 || isNaN(adoption) ? null : engagmentData,
        chartType: CHART_TYPE.PIE,
      },
      space: 'lg:row-start-1 lg:col-start-2 sm:row-start-2 sm:col-start-1',
    },
    {
      Component: CustomChart,
      props: {

        title: 'Outcomes: Mood and Irritability',
        chartData:moodData,
        chartType: CHART_TYPE.LINE,
      },
      space: 'lg:row-start-2 lg:col-start-1 lg:col-span-2 sm:row-start-3 sm:col-start-1',
    },
    {
      Component: CustomChart,
      props: {

        title: 'Outcomes: Daytime Functioning (Productivity Proxy)',
        chartData:dayTime,
        chartType: CHART_TYPE.AREA,
      },
      space: 'lg:row-start-3 lg:col-start-1 lg:col-span-2 sm:row-start-3 sm:col-start-1',
    },
    {
      Component: CustomChart,
      props: {

        title: 'Outcomes: Daytime Functioning (Productivity Proxy)',
        chartData:fallAsleep,
        chartType: CHART_TYPE.LINE,
        showEmoji:true
      },
      space: 'lg:row-start-4 lg:col-start-1 lg:col-span-2 sm:row-start-3 sm:col-start-1',
    },
    {
      Component: CustomChart,
      props: {

        title: 'Outcomes: Sleep Duration (hours/night)',
        chartData:sleepDuration,
        chartType: CHART_TYPE.AREA,
      },
      space: 'lg:row-start-5 lg:col-start-1 lg:col-span-2 sm:row-start-3 sm:col-start-1 mb-5',
    },
  ];
};

export default DashboardComponents;

