/* eslint-disable jsx-a11y/img-redundant-alt */
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import ChorusLogo from "assets/logos/chorus-sleep-logo.svg";
import Toast from "components/Utils/Toast";
import { TOAST_TYPE } from "components/Utils/constants/enum";
import { LoadingSmall } from "components/Utils/Loading";
import Footer from "components/AppHeaderFooter/Footer";
import { APIError } from "backend/api/service";
import { apiLogin , apiUseCode} from "backend/api/users";

interface LoginFormProps {
  onToggle: () => void;
  refetch: () => void;
}



const LoginForm: React.FC<LoginFormProps> = ({ onToggle, refetch }) => {
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [needPin, setNeedPin] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  const loginMutation = useMutation(apiLogin, {
    onSuccess: (data) => {
      setNeedPin(true);
    },
    onError: (error: unknown) => {
      if (error instanceof APIError) {
        console.log("API Error: ", error.code, error.title, error?.msg);
        setToastMsg(`${error.title}`);
      } else {
        // Handle other types of errors
        const errorMessage = (error as Error).message;
        console.error("Login failed:", error);
        setToastMsg(`Login failed: ${errorMessage}`);
      }
    },
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginMutation.mutate(email);
  };

  const submitPinMutation = useMutation(apiUseCode, {
    onSuccess: (data) => {
      refetch();
    },
    onError: (error: unknown) => {
      if (error instanceof APIError) {
        // Handle custom APIError
        console.log("API Error: ", error.code, error.title, error.msg);
        setToastMsg(`${error.title}`);
      } else {
        // Handle other types of errors
        const errorMessage = (error as Error).message;

        console.error("Login failed:", error);
        setToastMsg(`Login failed: ${errorMessage}`);
      }
    },
  });
  const handleSubmitPin = (e: React.FormEvent) => {
    e.preventDefault();
    submitPinMutation.mutate({ email, pin });
  };

  if (needPin) {
    return (
      <form onSubmit={handleSubmitPin} className="bg-darkGray2 p-6 rounded text-left">
        <h2 className="text-left text-2xl font-bold mb-6">Enter Pin</h2>
        <p className="mb-2 text-sm opacity-50 mb-6">
          You should have received a six-digit pin code in your email {email}. Please enter it
          below.
          <br />
          Having any issues? Let us help{" "}
          <a className="underline" href="mailto:techsupport@chorussleep.com">
            techsupport@chorussleep.com
          </a>
        </p>
        <label className="block text-sm opacity-50 ml-1 mb-1">Six Digit Pin Code</label>
        <input
          type="text"
          pattern="\d{6}"
          maxLength={6}
          minLength={6}
          value={pin}
          placeholder="Enter Pin"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPin(e.target.value)}
          className="w-full mb-6 p-2 border-2 border-charcoalC1 rounded bg-charcoalC1 focus:outline-none focus:ring-4 focus:ring-blueC1 focus:border-transparent"
          required
        />

        <button
          disabled={submitPinMutation.isLoading}
          type="submit"
          className="w-full bg-blueC1 text-white py-2 rounded mb-8"
        >
          {submitPinMutation.isLoading ? <LoadingSmall /> : "Confirm"}
        </button>
        <button
          type="button"
          onClick={() => {
            setNeedPin(false);
            setEmail("");
            setPin("");
            setToastMsg("");
          }}
          className="w-full text-white text-sm py-2 underline mb-8"
        >
          Start Over
        </button>
        {toastMsg && <Toast message={toastMsg} close={() => setToastMsg("")} toastType={TOAST_TYPE.ERROR}/>}
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="bg-darkGray2 p-6 rounded text-left">
      <h2 className="text-left text-2xl font-bold mb-6">Login</h2>

      <label className="block text-sm opacity-50 ml-1 mb-1">Email Address</label>
      <input
        type="email"
        value={email}
        placeholder="Enter Email"
        onChange={(e) => setEmail(e.target.value)}
        className="w-full mb-6 p-2 border-2 border-charcoalC1 rounded bg-charcoalC1 focus:outline-none focus:ring-4 focus:ring-blueC1 focus:border-transparent"
        required
      />
      <button
        disabled={loginMutation.isLoading}
        type="submit"
        className="w-full bg-blueC1 text-white py-2 rounded mb-8"
      >
        {loginMutation.isLoading ? <LoadingSmall /> : "Continue"}
      </button>
      <button
        type="button"
        onClick={onToggle}
        className="w-full text-white text-sm py-2 underline mb-8"
      >
        Don't have an account? Register
      </button>
      <p className="mb-2 text-xs text-center opacity-50">
        Having any issues? Let us help{" "}
        <a className="underline" href="mailto:techsupport@chorussleep.com">
          techsupport@chorussleep.com
        </a>
      </p>
      {toastMsg && <Toast message={toastMsg} close={() => setToastMsg("")} toastType={TOAST_TYPE.ERROR} />}
    </form>
  );
};

interface RegistrationFormProps {
  onToggle: () => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onToggle }) => {
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // Handle registration logic
  };

  return (
    <form onSubmit={handleSubmit} className="bg-darkGray2 p-6 rounded text-left">
      <h2 className="text-left text-2xl font-bold mb-6">Registration</h2>
      {/* <label className="block text-white mb-2">Email</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full mb-4 p-2 border-2 border-white rounded"
        required
      />
      <label className="block text-white mb-2">Nickname</label>
      <input
        type="text"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
        className="w-full mb-4 p-2 border-2 border-white rounded"
        required
      />
      <label className="block text-white mb-2">Timezone</label>
      <input
        type="text"
        value={timezone}
        onChange={(e) => setTimezone(e.target.value)}
        className="w-full mb-4 p-2 border-2 border-white rounded"
        required
      />
      <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded mb-4">
        Register
      </button>
       */}

      <TempRegPage />

      <button type="button" onClick={onToggle} className="w-full text-white text-sm py-2 underline">
        Already have an account? Login
      </button>
    </form>
  );
};

interface LoginRegPageProps {
  refetch: () => void;
}

const LoginRegPage: React.FC<LoginRegPageProps> = ({ refetch }) => {
  const [showLogin, setShowLogin] = useState(true);

  const toggleForm = () => {
    setShowLogin(!showLogin);
  };

  return (
    // <div className="bg-gray-900 min-h-screen flex items-center justify-center">
    <div className="min-h-screen bg-gradient-to-br from-black via-black to-[#767090] flex items-center justify-center">
      <img src={ChorusLogo} alt="Your image description" className="absolute top-0 left-0 p-4" />
      <div className="max-w-2xl text-center space-y-4 mx-2">
        <div className="max-w-md w-full">
          {showLogin ? (
            <LoginForm onToggle={toggleForm} refetch={refetch} />
          ) : (
            <RegistrationForm onToggle={toggleForm} />
          )}
        </div>
      </div>
      <Footer abs={true} />
    </div>
  );
};

const TempRegPage: React.FC = () => {
  return (
    <>
      <p className="text-sm opacity-50 mt-4 mb-8">
        For the best experience, please use our{" "}
        <a
          href="https://apps.apple.com/app/id1615658684"
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          iPhone
        </a>{" "}
        or{" "}
        <a
          href="https://play.google.com/apps/testing/com.flutterflow.chorussleep"
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Android
        </a>{" "}
        mobile app to create an account. Once you have done so, return here and log in. This will
        ensure you have the correct account settings.
      </p>
      <p className="mb-2 text-xs text-center opacity-50">
        Having any issues? Let us help{" "}
        <a className="underline" href="mailto:techsupport@chorussleep.com">
          techsupport@chorussleep.com
        </a>
      </p>
    </>
  );
};

export default LoginRegPage;
