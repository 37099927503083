import { useQuery } from 'react-query';
import { apiGetLesson, apiGetLessons, apiGetPastLessons } from '../api/content';
import { LESSONS_QUERY, LESSON_QUERY, LESSON_QUERY_WITH_ID, PAST_LESSON_QUERY } from './hooksConstant';

export const usePastLessons = () => {
  return useQuery([LESSON_QUERY, PAST_LESSON_QUERY], () => apiGetPastLessons(), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useLessons = () => {
  return useQuery([LESSONS_QUERY], () => apiGetLessons(), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useLesson = (id: string | undefined) => {
  return useQuery([LESSON_QUERY_WITH_ID, id], () => apiGetLesson(id), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};
