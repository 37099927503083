import React,{ FC, Fragment, useState } from 'react';
import MusicPlayer from '../MusicPlayer';
import SleepCard from '../SleepCard';

interface ISleepCardSlider {
  data: any;
  title?: string;
  subTitle?: string;
  cardType: string;
  width?: number;
  height?: number;
  testId: string;
}

const SleepCardSlider: FC<ISleepCardSlider> = (props) => {
  const { data, title, subTitle, cardType, height, width,testId } = props;

  const [sleepMusic, setSleepMusic] = useState<string | undefined>('');
  const [sleepTitle, setSleepTitle] = useState<string | undefined>('');

  const handelMusicPlay = (music: any | undefined) => {
    setSleepMusic(music?.content);
    setSleepTitle(music?.title);
  };

  return (
    <Fragment>
      {title && <p className="text-3xl font-bold text-white">{title}</p>}
      {subTitle && <p className="text-1xl mt-4 text-lightYellowC1_2">{subTitle}</p>}
      <div className="customOverFlowClass">
        {data.map((item: any, index: number) => (
          <SleepCard
            key={index}
            id={index}
            width={width}
            height={height}
            title={item.title}
            cardType={cardType}
            logo={item.logo_image}
            bgImage={item.image1}
            content={item.content_link}
            onClick={(data) => handelMusicPlay(data)}
            testId={testId}
          />
        ))}
      </div>
      {sleepMusic && <MusicPlayer music={sleepMusic} handelClose={() => setSleepMusic('')} title={sleepTitle} />}
    </Fragment>
  );
};

export default SleepCardSlider;
