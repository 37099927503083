import useUser from 'backend/hooks/useUser';
import { FC } from 'react';
import GroupUserItem from './GroupUserItem';

const GroupDetail: FC = () => {
  const {
    data: {
      body: { group: groupData },
    },
  } = useUser();

  const { num_active, num_employees, logo_small_link, name, id } = groupData;
  const adoption =  Math.ceil(((num_active / num_employees) * 100)) | 0;

  return (
    <>
      <div className="mt-4">{logo_small_link ? <img className="w-40" src={logo_small_link} alt="wcg-logo" /> : <div className="w-[100px] h-[100px] bg-customPurple rounded-full"></div>}</div>
      <h2 className="text-3xl mb-6 font-medium">{name ? name : 'WCG'}</h2>
      <div className="mb-12 p-2 text-center">Employees use code: {id}</div>
      <div className="flex space-x-0 mb-8 flex-col sm:flex-row sm:space-x-16">
        <GroupUserItem label={'Eligible Employees'} total={num_employees} />
        <GroupUserItem label={'Adopted Employees'} total={num_active} />
        <GroupUserItem label={'Adoption'} total={isNaN(adoption) ? '0' : adoption.toString() + '%'} />
      </div>
    </>
  );
};

export default GroupDetail;
