import { useQuery } from "react-query";
import { apiGetChartData} from "../api/users";
import { STATS_QUERY } from "./hooksConstant";

const useStats = () => {
  return useQuery([STATS_QUERY], () => apiGetChartData(), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export default useStats;
