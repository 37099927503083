import ErrorBox from "components/Utils/ErrorBox";
import GrayBox from "components/Utils/GrayBox";
import { Loading } from "components/Utils/Loading";

const DesignSystemPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-2xl text-center space-y-4">
        <GrayBox>
          <Loading />
        </GrayBox>

        <GrayBox>
          <ErrorBox msg={""} />
        </GrayBox>
      </div>
    </div>
  );
};

export default DesignSystemPage;
