import { useLesson } from '../../../backend/hooks/useLessons';
import CustomButton from '../../CustomButton';
import { Loading } from '../../Utils/Loading';
import React,{ FC, useState } from 'react';
import { useParams } from 'react-router-dom';

const DetailLessonCard: FC = () => {
  const { id } = useParams();

  const { data, isLoading } = useLesson(id);

  const [contentPage, setContentPage] = useState(0);

  const lessonContent = data?.body?.lesson?.pages;

  const handelNextPage = () => {
    setContentPage(contentPage + 1);
  };

  if (isLoading) {
    return (
      <div className="mb-3 mt-3 flex justify-center">
        <Loading />
      </div>
    );
  }

  return (

      <div className="bg-[#504D55] p-4 rounded-lg shadow mb-2 mt-5 ">
        <div className="inline-flex flex-col justify-between align-center">
          {lessonContent && <div dangerouslySetInnerHTML={{ __html: lessonContent[contentPage].content }} className="opacity-80 font-light text-sm mb-6 custom-underline"></div>}
          <CustomButton disabled={contentPage === lessonContent.length - 1} onClick={handelNextPage}>
            {lessonContent[contentPage].next_btn_text}
          </CustomButton>
        </div>
      </div>
  );
};

export default DetailLessonCard;
