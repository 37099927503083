import React, { FC } from 'react';

interface ILessonCard {
  title: string;
  day: number;
  duration: number
}

const LessonCard: FC<ILessonCard> = (props) => {
  const { title, day, duration } = props;
  return (
    <div className="bg-darkGray2 p-4 rounded-lg shadow mb-2">
      <div className="flex flex-col justify-between">
        <div className="text-lg font-bold">{title}</div>
        <div className="text-sm">Day {day} - {duration} mins</div>
      </div>
    </div>
  );
};

export default LessonCard;
