import React from "react";
import GrayBox from "./GrayBox";

interface ErrorBoxProps {
  msg: string | any;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ msg }) => {
  return (
    <GrayBox>
      <div className="space-y-4">
        <h2 className="text-xl">Something went wrong 😔</h2>
        <p>
          We're sorry, we couldn't complete this request right now. Please try again in a few
          minutes or contact techsupport@chorussleep.com
        </p>
        <p>
          Please include the name or link of the page you are accessing, which device you are using
          (iPhone, Android, Chrome Browser, etc), and any other details that might help us track
          down the issue. Thank you!
        </p>
        <p>Error details: {msg}</p>
      </div>
    </GrayBox>
  );
};

export default ErrorBox;
