import React, { FC } from 'react';
import { CheckSquareFilled, StarFilled } from '@ant-design/icons';

interface KeyInsightsProps {
  data: {
    icon: string;
    content: string;
    title: string;
  };
}

const KeyInsights: FC<KeyInsightsProps> = (props) => {
  const {
    data: { icon, content, title },
  } = props;

  const iconList: {
    [key: string]: JSX.Element;
  } = {
    green_check: <CheckSquareFilled className="text-green" />,
    blue_star: <StarFilled className="text-blue-500" />,
    red_dot: <div className="w-4 h-4 bg-red-500 rounded-full mt-1"></div>,
  };

 

  return (
    <div className="sm:flex items-start mb-3">
      <div className="flex">
      <div className="leading-[1.2]">{iconList[icon]}</div>
        <p className="ml-1 font-extralight flex">
          <span className="font-bold" >
            {title}:&nbsp;&nbsp;
          </span>
        </p>
      </div>
      <span className='customTextWhite'  dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default KeyInsights;
