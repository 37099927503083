import React,{ FC } from 'react';

interface ISleepSoundsCard {
  id?: number;
  width?: number;
  height?: number;
  title: string;
  cardType?: string;
  logo?: string;
  bgImage?: string;
  content?: string;
  onClick?: (content?: object) => void;
  testId: string;
}

const SleepCard: FC<ISleepSoundsCard> = (props) => {
  const { width = 200, height = 200, title = 'Sleep Sounds', cardType = 'sound', logo, bgImage, onClick, content, id, testId } = props;

  return (
    <div
      onClick={() => {
        onClick && onClick({ content, title });
      }}
      data-testid={`${testId}-${id}`}
      className={`bg-darkGray2 p-4 cursor-pointer rounded-lg shadow mb-2 mt-2 min-w-[${width}px] min-h-[${height}px] max-w-[300px] max-h-[300px] flex justify-around items-center flex-col p-4`}
    >
      {cardType === 'sound' ? (
        <img src={logo} alt={title} style={{ width: '80px', height: 'auto', marginBottom: '10px' }} className="custom-white mb-10" />
      ) : (
        <img src={bgImage} alt={title} style={{ width: '100px', height: 'auto', marginBottom: '10px' }} />
      )}

      <p className="text-center">
        {title}
      </p>
    </div>
  );
};

export default SleepCard;
