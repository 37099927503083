import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import musicImage from '../../assets/logos/giphy.gif';

interface IMusicPlayer {
  music: string | undefined;
  handelClose: () => void;
  title: string | undefined;
}

const MusicPlayer: FC<IMusicPlayer> = (props) => {
  const { music, handelClose, title } = props;
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handelStop = () => {
    if (audioRef && audioRef.current && isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false)

      handelClose();
    }
  };

  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.play().catch(error => {
        console.error("Error playing audio: ", error);
      });
      setIsPlaying(true)
    }
  }, [music, isPlaying]);


  return (
    <Fragment>
      <div className="fixed left-1/2 top-1/2 z-50 w-64 -translate-x-1/2 -translate-y-1/2 transform rounded-md bg-darkGray p-4 shadow-md" data-testid="music-player">
        <div className="absolute right-2 top-2 h-6 w-6 cursor-pointer text-gray-600" onClick={handelStop} data-testid="stop-music-button">
          <svg className="w-6 h-6" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </div>
        {title && <h2 className="text-sm text-white mb-2" data-testid="music-title">{title}</h2>}
        <img src={musicImage} alt="music" style={{ height: '80px', width: '100%' }} />
      </div>
      {music && <audio ref={audioRef} src={music} />}
    </Fragment>
  );
};

export default MusicPlayer;
