import { FC } from 'react';
import TopRightUserMenu from './TopRightUserMenu';
interface HeaderProps {
  collapsed: Boolean;
}

const Header: FC<HeaderProps> = ({ collapsed }) => {
  return (
    <header className={`z-[1] bg-darkGray2 p-4 flex items-center justify-between border-b border-opacity-5 border-white fixed w-full`}>
      <div></div>
      {/* <p className="mx-auto">
        Chorus Sleep <MidnightGradient /> <br />
        User Application / Leadership Portal
      </p> */}
      <div className="relative">
        <TopRightUserMenu />
      </div>
    </header>
  );
};

export default Header;
