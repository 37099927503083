import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid';
import React,{ FC, Fragment, useRef, useState } from 'react';
import { ISleepSessionSlider } from './SessionSlider/SleepSessionSlider';

const SleepSoundCard: FC<ISleepSessionSlider> = (props) => {
  const { background_image, content_link, title, description, id, testId = 'sleep-sound-card' } = props;

  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  
  const playAudio = () => {
    if (audioRef.current) {
      if (audioRef.current.readyState >= 3) {
        audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current && audioRef.current.addEventListener('canplaythrough', () => {
          if (audioRef.current) {
            audioRef.current.play();
            setIsPlaying(true);
          }
        }, { once: true });
      }
    }
    setIsPlaying(true);

  };
  

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  return (
    <Fragment>
      <div
        style={{ backgroundImage: `url(${background_image})` }}
        className="bg-no-repeat bg-cover  bg-center relative h-80 min-w-[400px] max-w-[400px] flex justify-center mt-2 mb-2 rounded-lg items-end p-3"
        data-testid={`${testId}-${id}`}
      >
        {isPlaying ? (
          <PauseIcon data-testid="pause-icon" onClick={stopAudio} className="h-10 w-10 text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        ) : (
          <PlayIcon data-testid="play-icon" onClick={playAudio} className="h-10 w-10 text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        )}

        <div className="bg-darkGray2 rounded-lg p-3 w-full m-4">
          <p className="text-1xl font-bold truncate ..." data-testid={`sleep-sound-card-description-${id}`}>
            {description}
          </p>
          <p className="text-sm" data-testid={`sleep-sound-card-title-${id}`}>
            {title}
          </p>
        </div>
        <audio ref={audioRef} src={content_link} />
      </div>
    </Fragment>
  );
};

export default SleepSoundCard;
