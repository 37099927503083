import { memo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { CHART_TYPE } from 'components/Utils/constants/enum';
import { processData } from 'components/Dashboard/Chart/ChartFunctions';
interface CustomChartProps {
  labels?: Array<string>;
  colors: Array<string>;
  title: string;
  chartType: CHART_TYPE;
  chartData?: any;
  showEmoji?:boolean
}

interface State {
  options: {
    labels?: string[];
    colors: string[];
    responsive: {
      breakpoint: number;
      options: {
        legend: {
          position: 'bottom';
        };
      };
    }[];
    stroke: {
      show: boolean;
      colors: string[];
      curve: 'smooth' | 'straight' | 'stepline';
    };
    xaxis: any;
    tooltip: {
      theme: 'dark';
    };
    yaxis: any;
    grid: any
  };
  series:any
}

const CustomChart = memo((props: CustomChartProps) => {
  const { labels, colors=['#c091d7', '#51bfc0', '#bfc051', '#d7c091'], title, chartType, chartData, showEmoji } = props;

  let { data: seriesData, xaxis } = processData(chartData);

  const state: State = {
    options: {
      ...(labels && { labels: [...labels] }),
      colors: colors,
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      stroke: {
        show: true,
        colors: ['#c091d7', '#51bfc0', '#bfc051', '#d7c091'],
        curve: 'smooth',
      },
      xaxis: {
        ...(xaxis.length > 0 && { ...xaxis[0] }),
        labels: {
          style: {
            colors: '#fff', 
          },
        },
      },
      tooltip: {
        theme: 'dark',
      },
      yaxis: {
        tickAmount:4,
        labels: {
        ...(showEmoji  &&   {formatter: function (val:any) {
          val = Math.round(val)
          if(val < 20) {
            return '😢 ' + val;
          } else if(val < 40) {
            return '😐 ' + val;
          } else if(val < 60) {
            return '😊 ' + val;
          } else {
            return '🥳 ' + val;
          }
        }}),
          offsetX: -10,
          style:{
            colors:'#fff'
          }
        },
      },
      grid: {
        borderColor: '#282828', 
      },
    },

    series: labels ? chartData : seriesData,
  };

  return (
    <div className="w-full block p-4 h-[500px] relative">
      <h5 className="text-1xl font-medium">{title}</h5>
      {chartData ? (
        <ReactApexChart options={state.options} series={state.series} type={chartType} width="100%" height="100%" />
      ) : (
        <div className="p-2 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">No data for preview</div>
      )}
    </div>
  );
});

export default CustomChart;
