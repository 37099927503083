// **************************************************************************************
// Local storage (just use directly, it's quite fast) - Our only storage currently
// **************************************************************************************

import { isBrowser } from "./query";

export function localGet(key: string): any | null {
  if (!isBrowser()) {
    // Gatsby will fail when generating pages
    return null;
  }
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item);
}

export function localPut(key: string, val: any): void | null {
  if (!isBrowser()) {
    // Gatsby will fail when generating pages
    return null;
  }
  localStorage.setItem(key, JSON.stringify(val));
}

export function localDel(key: string): void | null {
  if (!isBrowser()) {
    // Gatsby will fail when generating pages
    return null;
  }
  localStorage.removeItem(key);
}
