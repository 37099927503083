import React, { useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { useQueryClient } from 'react-query';
import { logout } from '../../backend/auth_funcs';
import { localGet } from '../../backend/storage';
import { getLatestVersion } from '../../backend/versions';

const TopRightUserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const email = localGet('user').email;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative z-10" data-testid="user-menu">
      <button onClick={toggleDropdown} className="bg-themeBlue p-2 rounded-full focus:outline-none" data-testid="user-menu-button">
        <AiOutlineUser className="text-white" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 bg-darkGray2 border border-opacity-5 border-white" data-testid="dropdown">
          {/* <Link
            to="/settings"
            className="block px-4 py-2  hover:bg-themeBlue"
            onClick={toggleDropdown}
          >
            Settings
          </Link> */}
          <div className="px-4 py-2 hover:bg-themeBlue">
            Logged in as: <br />
            <span className="pl-2 text-xs italic">{email}</span>
          </div>

          <div onClick={() => getLatestVersion()} className="cursor-pointer px-4 py-2 hover:bg-themeBlue">
            Get Latest Version
          </div>
          <div
            onClick={() => {
              logout(queryClient);
            }}
            className="px-4 py-2 cursor-pointer hover:bg-themeBlue"
            data-testid="logout-button"
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default TopRightUserMenu;
