import { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import DisabledDashboard from "../components/DisabledDashboard";
import ErrorBox from "../components/Utils/ErrorBox";
import { LoadingPage } from "../components/Utils/Loading";
import LoginRegPage from "../pages/LoginRegPage";
import { LoggedOutError } from "./api/users";
import useUser from "./hooks/useUser";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: unknown) => {
        if (error instanceof Error && error.name === "LoggedOutError") {
          return false;
        } else {
          return failureCount < 3;
        }
      },
    },
  },
});

interface AppPageWrapProps {
  children: ReactNode;
}

export function AppPageWrap(props: AppPageWrapProps): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <HandleAuth>{props.children}</HandleAuth>
    </QueryClientProvider>
  );
}

interface HandleAuthProps {
  children: ReactNode;
}

function HandleAuth(props: HandleAuthProps): JSX.Element {
  const { isLoading, isError, error, data, refetch } = useUser();

  if (error instanceof LoggedOutError) {
    return <LoginRegPage refetch={refetch} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  let user = data?.body?.user ?? null;
  let group = data?.body?.group ?? null;
  if (user === null || group === null) {
    return <ErrorBox msg={"There was an error finding your user"} />;
  }

  if (!group.dash_enabled || !group.admin_emails.includes(user.email)) {
    return <DisabledDashboard email={user.email}/>;
  }

  if (isError) {
    return <ErrorBox msg={error} />;
  }

  return <>{props.children}</>;
}
