import React, { FC, Fragment } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ISleepScoreChartObject {
  label: string;
  point: number;
  user_confirmed: boolean;
}

interface ISleepScoreChartProps {
  data?: ISleepScoreChartObject[];
}

const SleepScoreChart: FC<ISleepScoreChartProps> = (props) => {
  const { data } = props;
  let labels: string[] = [];
  let points: number[] = [];

  const dataArray = data && data.length > 0 ? [...data.slice(0,7)].reverse() : [];

  dataArray.forEach((item) => {
    labels.push(item.label);
    points.push(item.point);
  });

  interface State {
    options: {
      labels?: string[];
      colors?: string[];
      responsive: {
        breakpoint: number;
        options: {
          legend: {
            position: 'bottom';
          };
        };
      }[];
      stroke: {
        show: boolean;
        colors: string[];
        curve: 'smooth' | 'straight' | 'stepline';
      };
      xaxis: any;
      tooltip: {
        theme: 'dark';
      };
      yaxis: any;
      grid: any;
    };
    series: any;
  }

  const state: State = {
    options: {
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      stroke: {
        show: true,
        colors: ['#c091d7', '#51bfc0', '#bfc051', '#d7c091'],
        curve: 'smooth',
      },
      xaxis: {
        categories: labels,
        labels: {
          style: {
            colors: '#fff',
          },
        },
      },
      tooltip: {
        theme: 'dark',
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
          formatter: (value: number) => `${value}%`,
          style: {
            colors: '#fff',
          },
        },
      },
      grid: {
        borderColor: '#282828',
      },
    },

    series: [
      {
        name: 'Desktops',
        data: points,
      },
    ],
  };

  return (
    <Fragment>
      <p className="text-3xl font-bold text-white">Track & Measure</p>
      <p className="text-1xl mt-4 text-lightYellowC1_2">Sleep Score</p>
      <div style={{ height: '400px' }}  data-testid="sleep-score-chart">
        <ReactApexChart options={state.options} series={state.series} type={'line'} width="100%" height="100%" />
      </div>
    </Fragment>
  );
};

export default SleepScoreChart;
